import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img1 from "../../images/solar/1.jpg"
import img2 from "../../images/solar/2.jpg"

import intro from "../../images/solar/intro.mp4"
import iss from "../../images/solar/iss.mp4"




// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;



export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    Zürichians are now able to invest in their own solar panels to shrink their energy bill.
                    <p/>
                    This unconventional product needed an unconventional story. So <a href="http://www.kilokilo.ch" target="_blank">KiloKilo (digital agency)</a> and <LinkABOUT location={location}>I</LinkABOUT> created this sales plattform.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </English>


          <Deutsch Language={location.search}>
              <Type>
                    Zürcher können neu in ihre eigenen Solarpanels investieren um ihre Stromrechnung schrumpfen zu lassen.
                    <p/>
                    Dieses unkonventionelle Produkt brauchte eine unkonventionelle Story. Deshalb erschufen <a href="http://www.kilokilo.ch" target="_blank">KiloKilo</a> und <LinkABOUT location={location}>Ich</LinkABOUT> diese Verkaufsplattform.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </Deutsch>

    </div>

    <Element name="test1"></Element>



    <video  autoPlay loop muted>
<source src={intro} type="video/mp4" />
Your browser does not support the video tag.
</video>



      <div className="comment">
        <English Language={location.search}>
              <Type>

              An interactive video explains the concept while keeping users engaged with small interactions.

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    
                    Ein interaktives Video erklärt das Konzept und fixiert die Aufmerksamkeit der Besucher mit kleinen Interaktionen.

              </Type>
        </Deutsch>

      </div>


      <img src={img1}/>







      <div className="comment">
        <English Language={location.search}>
              <Type>

              The zoomable 3D map of Zürich shows all available solar panels (they are sold out most of the time).

              </Type>

        </English>

        <Deutsch Language={location.search}>
              <Type>
                    
                Die zoombare 3D Karte Zürichs zeigt alle vorhandenen Solarpanels (sie sind meistens ausverkauft).

              </Type>
        </Deutsch>

      </div>

      <img src={img2}/>


      <div className="comment">
        <English Language={location.search}>
              <Type>

              We also wanted to show the impact of a solar plant, so we used the international space station as a comparison, naturally.

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    
                  Wir wollten auch die Relevanz einer Solaranlage aufzeigen, also verwendeten wir die internatonale Raumstation als Vergleich (was denn sonst).

              </Type>
        </Deutsch>

      </div>




      <video  autoPlay loop muted>
<source src={iss} type="video/mp4" />
Your browser does not support the video tag.
</video>








      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
